
































import Vue from "vue";
import { dataProjects } from "@/models/Types/Post/DataProjects";
import { Post } from "@/models/Types/Post/Post";
import { dataNews } from "@/models/Types/Post/DataNews";
import { readingTime } from "@/components/Pages/Post/PostHelpers";

export default Vue.extend({
  async mounted() {
    const routeName = this.$route.name?.toLocaleLowerCase();
    const id = this.$route.params.id;
    const projects = await dataProjects;
    const news = await dataNews;
    let post: Post | undefined = undefined;
    if (routeName === "project") {
      post = projects.filter(el => el.id === id).pop();
    } else if (routeName === "news") {
      post = news.filter(el => el.id === id).pop();
    }
    if (!post) {
      // show 404;
      this.$router.push("/404");
      return;
    }

    this.post = post;
    this.readingTime = readingTime(post.content + post.title);
  },
  data() {
    const post: Post | {} = {};
    return { post, readingTime: "0" };
  }
});
