



















import Vue from "vue";
import { dataNews } from "@/models/Types/Post/DataNews";
import { Post } from "@/models/Types/Post/Post";

export default Vue.extend({
  async mounted() {
    this.news = await dataNews;
  },
  data() {
    const news: Post[] = [];
    return { news: news };
  }
});
